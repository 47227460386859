import { Navigate, useRoutes } from 'react-router-dom';
import {useSelector} from 'react';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Home from './pages/Home';
import Products from './pages/Products';
import Stores from './pages/Stores';
import Blog from './pages/Blog';
import User from './pages/User';
import NotFound from './pages/Page404';
import Form from './pages/Forms';
import SheetQ from './pages/SheetsQ';

// ----------------------------------------------------------------------

export default function Router(props) {

    console.log(props.auth);
    // const { isLoggedIn } = useSelector((state) => state.auth);
    const routes = () =>[
        {
            path: '/dashboard',
            element: props.auth ? <DashboardLayout /> : <Navigate to="/login"/>,
            children: [
                { element: <Navigate to="/dashboard/app" replace /> },
                { path: 'app', element: <DashboardApp /> },
                { path: 'user', element: <User /> },
                { path: 'products', element: <Products /> },
                { path: 'stores', element: <Stores /> },
                { path: 'blog', element: <Blog /> },
                { path: 'home', element: <Home /> },
                { path: 'forms', element: <Form /> },
                { path: 'sheetQ', element: <SheetQ /> }
            ],
        },
        {
            path: '/',
            element: <LogoOnlyLayout />,
            children: [
                { path: 'login', element: <Login onLogin={props.onLogin} /> },
                { path: 'register', element: <Register /> },
                { path: '404', element: <NotFound /> },
                { path: '/', element: <Navigate to="/dashboard" /> },
                { path: '*', element: <Navigate to="/404" /> }
            ]
        },
        { path: '*', element: <Navigate to="/404" replace /> }
    ]
    return useRoutes(routes());
}
