import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import baselineLocalHospital from '@iconify/icons-ic/baseline-local-hospital';
import home from '@iconify/icons-ic/round-home';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import roundText from '@iconify/icons-ic/round-text-snippet';
import folderFill from '@iconify/icons-eva/folder-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
    {
        title: 'dashboard',
        path: '/dashboard/app',
        icon: getIcon(pieChart2Fill)
    },
    {
        title: 'home',
        path: '/dashboard/home',
        icon: getIcon(home)
    },
    // {
    //     title: 'pacientes',
    //     path: '/dashboard/user',
    //     icon: getIcon(peopleFill)
    // },
    {
        title: 'doctores',
        path: '/dashboard/products',
        icon: getIcon(baselineLocalHospital)
    },
    {
        title: 'clinicas',
        path: '/dashboard/stores',
        icon: getIcon(baselineLocalHospital)
    },
    {
        title: 'Formularios',
        path: '/dashboard/forms',
        icon: getIcon(roundText)
    },
    {
        title: 'Hoja Quirúrgica',
        path: '/dashboard/sheetQ',
        icon: getIcon(folderFill)
    }
    // {
    //   title: 'blog',
    //   path: '/dashboard/blog',
    //   icon: getIcon(fileTextFill)
    // },
    // {
    //   title: 'login',
    //   path: '/login',
    //   icon: getIcon(lockFill)
    // },
    // {
    //   title: 'register',
    //   path: '/register',
    //   icon: getIcon(personAddFill)
    // },
    // {
    //   title: 'Not found',
    //   path: '/404',
    //   icon: getIcon(alertTriangleFill)
    // }
];

export default sidebarConfig;
