/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import arrowCircleLeftFill from '@iconify/icons-eva/arrow-circle-left-fill';
import saveFill from '@iconify/icons-eva/save-fill';
import editFill from '@iconify/icons-eva/edit-fill';

// material
import {
    Card,
    Stack,
    Button,
    Container,
    Typography,
    TextField,
} from '@mui/material';

import {TreeView, TreeItem} from '@mui/lab';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import {ChevronRightIcon, ExpandMoreIcon} from '@mui/icons-material';

// components
import CreateSheetModal from '../components/SheetsQModals';
import { getFolders, addFolder} from '../functions/database';
import Page from '../components/Page';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    overflow: 'auto',
    height: '100%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '2rem',
    boxShadow: 24,
    p: 4
};

export default function Sheet() {
    const [open, setOpen] = useState(false);
    const [showBack, setShowBack] = useState(false);
    const [selectedLV, setSelectedLV] = useState('0');
    const [aFolders, setAfolders ] = useState([]);
    const [showEdit, setShowEdit] = useState(true);
    const [hint, setHint] = useState("");
    const [aFilteredFolders, setAFilteredFolders ] = useState([]);
    const [expanded, setExpanded] = useState([]);

    useEffect(() => {
        getFolders().then((folders) => {
            if (folders) setAfolders(folders);
                setAFilteredFolders(folders);
        });
    }, []);

    return (
        <Page title="TraumaReports: User | Brands">
            <Container>
                <CreateSheetModal 
                    handleAddFolder={(folder) => {  
                        const newFolder = {
                                    parent: selectedLV,
                                    hint: "Por favor, seleccione una opción",
                                    childs : [],
                                    id: (aFolders.length + 1).toString(),
                                    name: folder
                                };
                        aFolders.push(newFolder);
                        setAfolders(aFolders);
                        addFolder({folders: aFolders});
                    }} 
                    open={open} 
                    handleClose={() => setOpen(false)} 
                    style={style} 
                />
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={5}
                >
                    <Typography variant="h4" gutterBottom>
                        Hoja Quirúrgica
                    </Typography>
                    <Button
                        variant="contained"
                        component={Button}
                        startIcon={<Icon icon={plusFill} />}
                        onClick={() => {
                            setOpen(true);
                        }}
                    >
                        Crear nuevo
                    </Button>
                </Stack>
                <Card
                    style={{
                        padding: '1rem'
                    }}
                >
                    {showBack ? 
                    (<Stack display="flex" justifyContent="space-evenly" flexDirection="row" paddingBottom="2rem">
                        <Button
                            style={{width:"5%"}}
                            variant="text"
                            component={Button}
                            startIcon={<Icon icon={arrowCircleLeftFill} />}
                            onClick={() => {
                                const parent = getParent(aFolders, selectedLV)[0];
                        
                                if(parent){
                                    if (parent.parent === "0") {
                                        setShowBack(false);
                                    }
                                    setSelectedLV(parent.parent);
                                }else{
                                    setShowBack(false);
                                }
                            }}
                        />
                        {showEdit ? 
                        <>
                            <div style={{width:"80%"}}>
                                {hint === '' ? 'Por favor, edita el texto a mostrar...' : hint}
                            </div>
                            
                            <Button
                                style={{width:"5%"}}
                                variant="contained"
                                component={Button}
                                startIcon={<Icon icon={editFill} />}
                                onClick={() => {
                                    setShowEdit(false);
                                }}
                            />
                        </>
                        :
                            <>
                                <TextField
                                    style={{width:"85%"}}
                                    id="standard-basic"
                                    label="Texto a mostrar al seleccionar"
                                    variant="standard"
                                    value={hint}
                                    onChange={(e) => {
                                        setHint(e.target.value);
                                    }}
                                    InputProps={{
                                        readOnly: showEdit,
                                    }}
                                />
                                <Button
                                    style={{width:"5%"}}
                                    variant="contained"
                                    component={Button}
                                    startIcon={<Icon icon={saveFill} />}
                                    onClick={() => {
                                        const ind = aFolders.findIndex((fold) => fold.id === selectedLV);
                                        if(ind > -1){
                                            aFolders[ind].hint = hint;
                                            addFolder({folders: aFolders});
                                        }
                                        setShowEdit(true);
                                    }}
                                />
                            </>
                            
                        }
                    </Stack>) : null }
                    {filterByLV(aFolders, selectedLV).length > 0 ?
                        <div
                            style={{
                                display: 'grid',
                                alignContent: 'space-around',
                                alignItems: 'stretch',
                                justifyItems: 'stretch',
                                gridTemplateColumns: `repeat(7, 1fr)`,
                                gridAutoColumns: "max-content",
                                gridGap: '15px'
                            }}
                        >
                            { createFolders( filterByLV(aFolders, selectedLV), aFilteredFolders , setSelectedLV, setShowBack, setAfolders, setHint)}
                        </div> : <h4>No hay carpetas creadas</h4>
                    }
                </Card>
                <Card>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={2}
                    mt={2}
                    pl={5}
                >
                    <Typography variant="h4" gutterBottom>
                        Mapa general
                    </Typography>
                </Stack>
                <TreeView
                    aria-label="file system navigator"
                    style={{color:"#17a2b8"}}
                    defaultCollapseIcon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM168 232C154.7 232 144 242.7 144 256C144 269.3 154.7 280 168 280H344C357.3 280 368 269.3 368 256C368 242.7 357.3 232 344 232H168z"/></svg>}
                    defaultExpandIcon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M384 32C419.3 32 448 60.65 448 96V416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V96C0 60.65 28.65 32 64 32H384zM224 368C237.3 368 248 357.3 248 344V280H312C325.3 280 336 269.3 336 256C336 242.7 325.3 232 312 232H248V168C248 154.7 237.3 144 224 144C210.7 144 200 154.7 200 168V232H136C122.7 232 112 242.7 112 256C112 269.3 122.7 280 136 280H200V344C200 357.3 210.7 368 224 368z"/></svg>}
                    sx={{ flexGrow: 1, overflowY: 'auto', padding: '1rem' }}
                    >
                    { createTree(filterByLV(aFolders, selectedLV), aFilteredFolders , setSelectedLV, setShowBack, setAfolders, setHint, aFolders, setExpanded, expanded)}
                </TreeView>
                </Card>
            </Container>
        </Page>
    );
}

const createFolders = (folders, folds, setParent, setShowBack, setAfolders, setHint) => folders.map((folder,ind) => (
        <Card
            key={`folder_${ind}`}
            style={{
                color: '#17a2b8',
                backgroundColor: '#f5f5f5',
                padding: '0.5rem',
                width: '100%',
                textAlign: 'center'
            }}
            onClick={()=>{
                setParent(folder.id);
                setHint(folder.hint|| '');
                setShowBack(true);
            }}
        >
            <div>
                <div
                    style={{
                        position: "absolute",
                        right: "0px",
                        top: "0px",
                        background: "red",
                        borderRadius: "50%",
                        height: "25px",
                        width: "25px",
                        color: "white",
                        textAlign: "center",
                        cursor: "pointer"
                    }}
                    
                    onClick={(e)=>{
                        const aFolds = folds.filter((fold) =>
                            fold.id !== folder.id
                        );
                        setAfolders(aFolds);
                        setParent(folder.parent);
                        
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                >
                    x
                </div>
                <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5cvPwmsGl6cCk3QgxZg_m2BC32dJ-Klv4YA&usqp=CAU"
                    alt=""
                />
            </div>
            <p>{folder.name}</p>
        </Card>
    )
);

const createTree = (folders, folds, setParent, setShowBack, setAfolders, setHint, aFolders, setExpanded, expanded) => folders.map((folder, ind)=>(
    <>
        <TreeItem 
            nodeId={folder.id} 
            label={folder.name}
            icon={
                <svg onClick={()=>{
                    setParent(folder.id);
                    setHint(folder.hint|| '');
                    setShowBack(true);
                }}  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M480 144V384l-96 96H144C117.5 480 96 458.5 96 432v-288C96 117.5 117.5 96 144 96h288C458.5 96 480 117.5 480 144zM384 264C384 259.6 380.4 256 376 256H320V200C320 195.6 316.4 192 312 192h-48C259.6 192 256 195.6 256 200V256H200C195.6 256 192 259.6 192 264v48C192 316.4 195.6 320 200 320H256v56c0 4.375 3.625 8 8 8h48c4.375 0 8-3.625 8-8V320h56C380.4 320 384 316.4 384 312V264zM0 360v-240C0 53.83 53.83 0 120 0h240C373.3 0 384 10.75 384 24S373.3 48 360 48h-240C80.3 48 48 80.3 48 120v240C48 373.3 37.25 384 24 384S0 373.3 0 360z"/></svg>
            } 
            expandIcon={
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M384 32C419.3 32 448 60.65 448 96V416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V96C0 60.65 28.65 32 64 32H384zM224 368C237.3 368 248 357.3 248 344V280H312C325.3 280 336 269.3 336 256C336 242.7 325.3 232 312 232H248V168C248 154.7 237.3 144 224 144C210.7 144 200 154.7 200 168V232H136C122.7 232 112 242.7 112 256C112 269.3 122.7 280 136 280H200V344C200 357.3 210.7 368 224 368z"/></svg>
            }
            collapseIcon={
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM168 232C154.7 232 144 242.7 144 256C144 269.3 154.7 280 168 280H344C357.3 280 368 269.3 368 256C368 242.7 357.3 232 344 232H168z"/></svg>
            }
        >
                {createTree(filterByLV(aFolders, folder.id), folds, setParent, setShowBack, setAfolders, setHint,aFolders, setExpanded, expanded)}
        </TreeItem>
    </>
    
));

const filterByLV = (folders, parent) => folders.filter((folder)=> folder.parent === parent);

const getParent = (folders, son) => folders.filter((folder) => folder.id === son);
