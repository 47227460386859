/* eslint-disable jsx-a11y/tabindex-no-positive */
import JoditEditor from 'jodit-react';
import { useEffect, useState, useRef } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
    Button,
    Container,
    Snackbar,
    Alert,
    CircularProgress,
    Backdrop
} from '@mui/material';
// components
import { addHome, getHome } from '../functions/database';
import Page from '../components/Page';
//
// import USERLIST from '../_mocks_/user';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Home() {
    const editor = useRef(null);
    const [content, setContent] = useState('');
    const [content2, setContent2] = useState('');
    const [originalContent, setOriginalContent] = useState('');
    const [originalContent2, setOriginalContent2] = useState('');
    const [open, handleClose] = useState(false);
    const [openSpinner, handleCloseSpinner] = useState(true);
    const config = {
        readonly: false // all options from https://xdsoft.net/jodit/doc/
    };

    useEffect(() => {
        getHome(true).then((home) => {
            if (home[0].content) {
                setContent(home[0].content);
                setOriginalContent(home[0].content);
            }
            handleCloseSpinner(false);
        });
        getHome(false).then((home) => {
            if (home[0].content) {
                setContent2(home[0].content);
                setOriginalContent2(home[0].content);
            }
            handleCloseSpinner(false);
        });
    }, []);

    return (
        <Page title="TraumaReports: User | Brands">
            <Backdrop
                sx={{
                    color: '#17a2b8',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={openSpinner}
                onClick={handleCloseSpinner}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container>
                <h1>Contenido de la página principal de doctores </h1>
                <JoditEditor
                    ref={editor}
                    value={content}
                    config={config}
                    tabIndex="1" // tabIndex of textarea
                    onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                    onChange={(newContent) => {}}
                />
                <Button
                    onClick={() => {
                        const data = {
                            content
                        };
                        if (originalContent !== content) {
                            addHome(data, true);
                            handleClose(true);
                        }
                    }}
                    color="success"
                    style={{ width: '100%' }}
                >
                    {' '}
                    Guardar{' '}
                </Button>
                <Snackbar
                    open={open}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    autoHideDuration={4000}
                    onClose={() => handleClose(false)}
                >
                    <Alert
                        onClose={() => handleClose(false)}
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        Guardado con éxito!
                    </Alert>
                </Snackbar>
            </Container>
            <Container>
                <h1>Contenido de la página principal de pacientes </h1>
                <JoditEditor
                    ref={editor}
                    value={content2}
                    config={config}
                    tabIndex="1" // tabIndex of textarea
                    onBlur={(newContent) => setContent2(newContent)} // preferred to use only this option to update the content for performance reasons
                    onChange={(newContent) => {}}
                />
                <Button
                    onClick={() => {
                        const data = {
                            content: content2
                        };
                        if (originalContent2 !== content2) {
                            addHome(data, false);
                            handleClose(true);
                        }
                    }}
                    color="success"
                    style={{ width: '100%' }}
                >
                    {' '}
                    Guardar{' '}
                </Button>
                <Snackbar
                    open={open}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    autoHideDuration={4000}
                    onClose={() => handleClose(false)}
                >
                    <Alert
                        onClose={() => handleClose(false)}
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        Guardado con éxito!
                    </Alert>
                </Snackbar>
            </Container>
        </Page>
    );
}
