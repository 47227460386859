import firebase from 'firebase';

export const getAllUsers = async () => {
    const users = [];
    await firebase
        .database()
        .ref('users')
        .get()
        .then((doc) => {
            doc.forEach((a) => {
                users.push(a.val());
            });
        });
    return users;
};

export const getResult = async () => {
    const result = await firebase.firestore().collection('results').get();
    return result.docs.length;
};

export const deleteForm = async (itemId) => {
    await firebase.firestore().collection('standardForms').doc(itemId).delete();
};

export const getHome = async (isDoctor) => {
    const homes = [];
    const docId = isDoctor ? 'iAzpPYQvoHfOk3W6i1Wc' : '2eljMfwDiJbd87aoUc7F';
    await firebase
        .firestore()
        .collection('home')
        .doc(docId)
        .get()
        .then((doc) => {
            homes.push(doc.data());
        });
    return homes;
};

export const addHome = async (data, isDoctor) => {
    const docId = isDoctor ? 'iAzpPYQvoHfOk3W6i1Wc' : '2eljMfwDiJbd87aoUc7F';
    await firebase.firestore().collection('home').doc(docId).set(data);
};

export const getForms = async () => {
    const forms = [];
    await firebase
        .firestore()
        .collection('standardForms')
        .get()
        .then((doc) => {
            doc.forEach((a) => {
                const b = a.data();
                b.id = a.id;
                forms.push(b);
            });
        });
    return forms;
};

export const addForm = async (data) => {
    await firebase.firestore().collection('standardForms').add(data);
};

export const updateForm = async (id, data) =>{ 
    console.log("erw")
    firebase.firestore().collection('standardForms')
        .doc(id).set(data);
}

export const getFolders = async () => {
    const folders = [];
    await firebase.firestore().collection('folders').get().then((doc) =>{
        doc.forEach((a) => {
            const b = a.data();
            b.id = a.id;
            folders.push(b);
        });
    });
    return folders.length > 0  && folders[0].folders ? folders[0].folders : [];
    
}

export const addFolder = async (data) => {
    await firebase.firestore().collection('folders').doc('default').set(data);
}

export const getFolders2 = async () => {
    const folders = [];
    await firebase.firestore().collection('folders2').get().then((doc) =>{
        doc.forEach((a) => {
            const b = a.data();
            b.id = a.id;
            folders.push(b);
        });
    });
    return folders.length > 0  && folders[0].folders ? folders[0].folders : [];
    
}

export const addFolder2 = async (data) => {
    await firebase.firestore().collection('folders2').doc('default').set(data);
}
