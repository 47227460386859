import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Stack,
    Switch,
    TextField,
    Typography,
    TextareaAutosize
} from '@mui/material';
import { useState } from 'react';
import { addForm, updateForm } from '../functions/database';

export default function CreateFormModal({ open, handleClose, style, folderId, protocol }) {
    const [name, setName] = useState('');
    const [shortName, setShortName] = useState('');
    const [chapters, setChapters] = useState([]);

    console.log(protocol);
    if (protocol && name === '') {
        setName(protocol.name);
        setShortName(protocol.shortName);
        setChapters(protocol.chapters);
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={5}
                >
                    <Typography variant="h4" gutterBottom>
                        Nuevo Formulario
                    </Typography>
                </Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-evenly"
                    mb={1}
                >
                    <TextField
                        id="standard-basic"
                        label="Nombre"
                        variant="standard"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                    />
                    <TextField
                        id="standard-basic"
                        label="Nombre Abreviado"
                        variant="standard"
                        value={shortName}
                        onChange={(e) => {
                            setShortName(e.target.value);
                        }}
                    />
                </Stack>
                <Button
                    fullWidth
                    variant="contained"
                    size="small"
                    onClick={() => {
                        const newChapter = {
                            name: '',
                            forDoctor: false,
                            questions: [
                                {
                                    name: '',
                                    forDoctor: false,
                                    type: 'text',
                                    value: '',
                                    points: 0,
                                    description: '',
                                }
                            ]
                        };
                        setChapters((prevChapters) => [
                            ...prevChapters,
                            newChapter
                        ]);
                    }}
                >
                    Añadir capítulo
                </Button>
                {chapters.map((chapter, ind) => (
                    <>
                        <Stack
                            key={`stack_chapter_${ind}`}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            mb={4}
                        >
                            <TextField
                                id="standard-basic"
                                label="Nombre del capítulo"
                                variant="standard"
                                value={chapter.name}
                                onChange={(e) => {
                                    setChapters(
                                        chapters.map((item, ind2) =>
                                            ind === ind2
                                                ? {
                                                    ...item,
                                                    name: e.target.value
                                                }
                                                : item
                                        )
                                    );
                                }}
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={chapter.forDoctor}
                                        onChange={(e) => {
                                            setChapters(
                                                chapters.map((item, ind2) =>
                                                    ind === ind2
                                                        ? {
                                                            ...item,
                                                            forDoctor:
                                                                e.target
                                                                    .checked
                                                        }
                                                        : item
                                                )
                                            );
                                        }}
                                        inputProps={{
                                            'aria-label': 'controlled'
                                        }}
                                        color="warning"
                                    />
                                }
                                label="For Doctor"
                            />

                            <Button
                                variant="contained"
                                size="small"
                                onClick={() => {
                                    const newQuestion = {
                                        name: '',
                                        type: 'text',
                                        value: '',
                                        forDoctor: chapter.forDoctor,
                                        description: '',
                                    };
                                    setChapters(
                                        chapters.map((item, ind2) => {
                                            const aQuestions = [
                                                ...item.questions
                                            ];
                                            aQuestions.push(newQuestion);
                                            return ind === ind2
                                                ? {
                                                    ...item,
                                                    questions: aQuestions
                                                }
                                                : item;
                                        })
                                    );
                                }}
                            >
                                Añadir Pregunta
                            </Button>
                        </Stack>

                        {chapter.questions.map((question, ind3) => (
                            <>
                                <Stack
                                    key={`question_${ind3}`}
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    mb={4}
                                >
                                    <TextField
                                        id="standard-basic"
                                        label="Pregunta..."
                                        variant="standard"
                                        value={question.name}
                                        onChange={(e) => {
                                            const oQuestion = { ...question };
                                            oQuestion.name = e.target.value;
                                            chapter.questions[ind3] = oQuestion;
                                            const aQuestions =
                                                chapter.questions;
                                            setChapters(
                                                chapters.map((item, ind2) =>
                                                    ind === ind2
                                                        ? {
                                                            ...item,
                                                            questions:
                                                                aQuestions
                                                        }
                                                        : item
                                                )
                                            );
                                        }}
                                    />
                                    <FormControl style={{ width: '50%' }}>
                                        <InputLabel id="demo-simple-select-label">
                                            Tipo de pregunta
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={question.type}
                                            label="Tipo de pregunta"
                                            onChange={(e) => {
                                                const oQuestion = {
                                                    ...question
                                                };
                                                oQuestion.type = e.target.value;
                                                if (
                                                    e.target.value ===
                                                    'dropdown'
                                                ) {
                                                    oQuestion.options = [
                                                        {
                                                            name: '',
                                                            value: '00',
                                                            points: 0
                                                        }
                                                    ];
                                                }
                                                chapter.questions[ind3] =
                                                    oQuestion;
                                                const aQuestions =
                                                    chapter.questions;
                                                setChapters(
                                                    chapters.map((item, ind2) =>
                                                        ind === ind2
                                                            ? {
                                                                ...item,
                                                                questions:
                                                                    aQuestions
                                                            }
                                                            : item
                                                    )
                                                );
                                            }}
                                        >
                                            <MenuItem value="text">
                                                Text
                                            </MenuItem>
                                            <MenuItem value="number">
                                                Numérico
                                            </MenuItem>
                                            <MenuItem value="date">
                                                Fecha
                                            </MenuItem>
                                            <MenuItem value="dropdown">
                                                Seleccionable
                                            </MenuItem>
                                            <MenuItem
                                                disabled
                                                value="multiInput"
                                            >
                                                Respuesta múltiple
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <Stack>
                                    <TextareaAutosize
                                        aria-label="description area"
                                        minRows={3}
                                        placeholder="Descripción"
                                        value={question.description}
                                        onChange={(e) => {
                                            const oQuestion = { ...question };
                                            oQuestion.description = e.target.value;
                                            chapter.questions[ind3] = oQuestion;
                                            const aQuestions =
                                                chapter.questions;
                                            setChapters(
                                                chapters.map((item, ind2) =>
                                                    ind === ind2
                                                        ? {
                                                            ...item,
                                                            questions:
                                                                aQuestions
                                                        }
                                                        : item
                                                )
                                            );
                                        }}
                                    />
                                </Stack>
                                {question.type === 'dropdown' ? (
                                    <>
                                        {question.options.map(
                                            (option, ind4) => (
                                                <Stack
                                                    direction="row"
                                                    spacing={2}
                                                    key={`row_option_${ind4}`}
                                                >
                                                    <TextField
                                                        size="small"
                                                        id="standard-basic"
                                                        style={{ width: '45%' }}
                                                        label="Opción"
                                                        variant="filled"
                                                        value={option.name}
                                                        onChange={(e) => {
                                                            const oOption = {
                                                                ...option
                                                            };
                                                            oOption.name =
                                                                e.target.value;
                                                            chapter.questions[
                                                                ind3
                                                            ].options[ind4] =
                                                                oOption;
                                                            const aQuestions =
                                                                chapter.questions;
                                                            setChapters(
                                                                chapters.map(
                                                                    (
                                                                        item,
                                                                        ind2
                                                                    ) =>
                                                                        ind ===
                                                                            ind2
                                                                            ? {
                                                                                ...item,
                                                                                questions:
                                                                                    aQuestions
                                                                            }
                                                                            : item
                                                                )
                                                            );
                                                        }}
                                                    />
                                                    <TextField
                                                        size="small"
                                                        id="standard-basic"
                                                        style={{ width: '45%' }}
                                                        label="Puntuación"
                                                        variant="filled"
                                                        value={option.points}
                                                        onChange={(e) => {
                                                            const oOption = {
                                                                ...option
                                                            };
                                                            oOption.points =
                                                                e.target.value;
                                                            oOption.value =
                                                                e.target.value;
                                                            chapter.questions[
                                                                ind3
                                                            ].options[ind4] =
                                                                oOption;
                                                            const aQuestions =
                                                                chapter.questions;
                                                            setChapters(
                                                                chapters.map(
                                                                    (
                                                                        item,
                                                                        ind2
                                                                    ) =>
                                                                        ind ===
                                                                            ind2
                                                                            ? {
                                                                                ...item,
                                                                                questions:
                                                                                    aQuestions
                                                                            }
                                                                            : item
                                                                )
                                                            );
                                                        }}
                                                    />
                                                </Stack>
                                            )
                                        )}

                                        <Stack direction="row" spacing={2}>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                onClick={() => {
                                                    const newOption = {
                                                        name: '',
                                                        value: '00',
                                                        points: 0
                                                    };
                                                    const oQuestion = {
                                                        ...question
                                                    };
                                                    oQuestion.options.push(
                                                        newOption
                                                    );
                                                    chapter.questions[ind3] =
                                                        oQuestion;
                                                    const aQuestions =
                                                        chapter.questions;
                                                    setChapters(
                                                        chapters.map(
                                                            (item, ind2) =>
                                                                ind === ind2
                                                                    ? {
                                                                        ...item,
                                                                        questions:
                                                                            aQuestions
                                                                    }
                                                                    : item
                                                        )
                                                    );
                                                }}
                                            >
                                                Añadir Opción
                                            </Button>
                                        </Stack>
                                    </>
                                ) : null}
                            </>
                        ))}
                    </>
                ))}

                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={4}
                >
                    <Button
                        color="error"
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => {
                            const data = {
                                name,
                                shortName,
                                copyright: 'Powered by TraumaReports',
                                chapters,
                                isStandard: false,
                                folder: folderId
                            };
                            if (!protocol) {
                                addForm(data);
                            } else {
                                updateForm(protocol.id, data);
                            }

                            handleClose(true);
                            
                        }}
                    >
                        {`${!protocol ? 'Crear': 'Editar'} formulario`}
                    </Button>
                </Stack>
            </Box>
        </Modal>
    );
}
