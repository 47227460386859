import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Box, Card, CardHeader } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------

const CHART_DATA = [
    { data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380] }
];

export default function AppConversionRates() {
    const chartOptions = merge(BaseOptionChart(), {
        tooltip: {
            marker: { show: false },
            y: {
                formatter: (seriesName) => fNumber(seriesName),
                title: {
                    formatter: (seriesName) => `#${seriesName}`
                }
            }
        },
        plotOptions: {
            bar: { horizontal: true, barHeight: '28%', borderRadius: 2 }
        },
        xaxis: {
            categories: [
                'CLINICA 1',
                'CLINICA 2',
                'CLINICA 3',
                'CLINICA 4',
                'CLINICA 5',
                'CLINICA 6',
                'CLINICA 7',
                'CLINICA 8',
                'CLINICA 9',
                'CLINICA 10'
            ]
        }
    });

    return (
        <Card>
            <CardHeader
                title="Ranking Centros Médicos"
                subheader="(+43%) que en 2020"
            />
            <Box sx={{ mx: 3 }} dir="ltr">
                <ReactApexChart
                    type="bar"
                    series={CHART_DATA}
                    options={chartOptions}
                    height={364}
                />
            </Box>
        </Card>
    );
}
