import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
    apiKey: 'AIzaSyDHj00WXvYEEhNnUgWnU44srreBaou9Ifk',
    authDomain: 'clinikform.firebaseapp.com',
    projectId: 'clinikform',
    databaseURL:
        'https://clinikform-default-rtdb.europe-west1.firebasedatabase.app',
    storageBucket: 'clinikform.appspot.com',
    messagingSenderId: '462429410606',
    appId: '1:462429410606:web:45fa0cd2fb755f9f22d6bf',
    measurementId: 'G-F1E4JH7M5G'
};

firebase.initializeApp(firebaseConfig);
// const auth = firebase.auth()

// export default { auth, firebase };
export default firebase;
