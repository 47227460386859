// material
import { Box, Grid, Container, Typography } from '@mui/material';
// components
import { useEffect, useState } from 'react';
import { getAllUsers, getResult } from '../functions/database';
import Page from '../components/Page';
import {
    AppTasks,
    AppNewUsers,
    AppBugReports,
    AppItemOrders,
    AppNewsUpdate,
    AppWeeklySales,
    // AppOrderTimeline,
    AppCurrentVisits,
    AppWebsiteVisits,
    // AppTrafficBySite,
    AppCurrentSubject,
    AppConversionRates
} from '../components/_dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardApp() {
    const [doctors, setDoctors] = useState([]);
    const [patients, setPatients] = useState([]);
    const [answers, setAnswers] = useState(0);

    useEffect(() => {
        getAllUsers().then((users) => {
            setDoctors(users.filter((user) => user.isDoctor));
            setPatients(users.filter((user) => !user.isDoctor));
        });

        getResult().then((ans) => {
            setAnswers(ans);
        });
    }, []);

    return (
        <Page title="TraumaReports: Dashboard | Brands">
            <Container maxWidth="xl">
                <Box sx={{ pb: 5 }}>
                    <Typography variant="h4">Hola, bienvenido!</Typography>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                        <AppWeeklySales doctors={doctors} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <AppNewUsers patients={patients} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <AppItemOrders answers={answers} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <AppBugReports />
                    </Grid>

                    <Grid item xs={12} md={6} lg={8}>
                        <AppWebsiteVisits />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <AppCurrentVisits />
                    </Grid>

                    <Grid item xs={12} md={6} lg={8}>
                        <AppConversionRates />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <AppCurrentSubject />
                    </Grid>

                    <Grid item xs={12} md={6} lg={8}>
                        <AppNewsUpdate />
                    </Grid>

                    {/* <Grid item xs={12} md={6} lg={4}>
                        <AppOrderTimeline />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <AppTrafficBySite />
                    </Grid> */}

                    <Grid item xs={12} md={6} lg={8}>
                        <AppTasks />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
