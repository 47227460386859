import {
    Box,
    Button,
    Modal,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { addFolder } from '../functions/database';

export default function CreateSheetModal({ open, handleClose, handleAddFolder, style }) {
    const [name, setName] = useState('');

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={5}
                >
                    <Typography variant="h4" gutterBottom>
                        Nueva Carpeta
                    </Typography>
                </Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-evenly"
                    mb={1}
                >
                    <TextField
                        id="standard-basic"
                        label="Nombre"
                        variant="standard"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                    />
                </Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={4}
                >
                    <Button
                        color="error"
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => {
                            handleAddFolder(name);
                            handleClose();
                        }}
                    >
                        Crear Carpeta
                    </Button>
                </Stack>
            </Box>
        </Modal>
    );
}
