import PropTypes from 'prop-types';
// material
// import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
    sx: PropTypes.object
};

export default function Logo({ sx, mini }) {
    return (
        // <Box component="img" src="/static/logo2.svg" sx={{ width: 200, height: 150, ...sx }} />;
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            style={{ width: mini ? '25%' : '100%' }}
            viewBox="0 0 682.000000 203.000000"
        >
            <g
                transform="translate(0.000000,203.000000) scale(0.100000,-0.100000)"
                fill="#000000"
                stroke="none"
            >
                <path
                    d="M250 1794 c-13 -32 -13 -66 0 -98 l9 -26 198 -2 198 -3 3 -588 2
-588 25 -9 c27 -10 229 -6 258 5 16 7 17 30 17 301 l0 294 93 0 c107 0 172
-24 222 -81 42 -48 56 -77 148 -309 82 -204 82 -204 117 -212 47 -10 130 1
137 19 6 15 -119 336 -175 448 -44 88 -75 129 -118 156 -19 11 -34 24 -34 27
0 4 29 22 65 41 126 64 180 159 179 308 -2 138 -59 230 -183 293 -87 44 -163
49 -674 50 l-477 0 -10 -26z m1042 -143 c46 -20 93 -67 110 -110 17 -45 12
-144 -10 -193 -41 -94 -124 -128 -307 -128 l-125 0 0 225 0 225 144 0 c119 0
152 -3 188 -19z"
                />
                <path
                    d="M1860 935 c0 -24 2 -25 75 -25 l75 0 0 -215 0 -215 30 0 30 0 0 215
0 215 75 0 c73 0 75 1 75 25 l0 25 -180 0 -180 0 0 -25z"
                />
                <path
                    d="M4260 720 l0 -240 25 0 c24 0 24 1 27 108 l3 107 36 3 c55 5 89 -30
129 -133 31 -79 35 -85 61 -85 16 0 29 5 29 11 0 25 -66 165 -92 195 -27 31
-28 33 -9 39 36 11 71 63 71 105 0 93 -55 130 -193 130 l-87 0 0 -240z m195
165 c34 -33 34 -83 -1 -116 -20 -19 -36 -24 -82 -24 l-57 0 -3 83 -3 82 61 0
c51 0 65 -4 85 -25z"
                />
                <path
                    d="M6128 878 c-3 -39 -6 -43 -30 -46 -20 -2 -28 -9 -28 -22 0 -13 8 -20
28 -22 l27 -3 5 -132 c6 -154 16 -174 87 -175 49 0 63 7 63 32 0 16 -5 18 -34
13 -55 -9 -67 20 -64 153 l3 109 48 3 c38 3 47 7 47 22 0 15 -9 19 -47 22
l-48 3 -3 43 c-3 37 -6 42 -27 42 -21 0 -24 -5 -27 -42z"
                />
                <path
                    d="M2247 834 c-4 -4 -7 -85 -7 -181 l0 -173 30 0 30 0 0 117 c0 110 1
118 26 150 24 31 31 34 66 31 38 -4 39 -3 36 27 -3 26 -8 30 -34 33 -22 2 -40
-5 -62 -22 -37 -32 -40 -32 -44 -4 -3 23 -27 35 -41 22z"
                />
                <path
                    d="M2528 824 c-37 -16 -55 -41 -40 -56 5 -5 25 -2 45 7 82 34 147 11
147 -53 0 -29 -2 -31 -47 -37 -81 -10 -121 -24 -142 -52 -41 -51 -19 -131 41
-152 44 -16 69 -14 113 9 47 24 45 24 45 5 0 -9 9 -15 25 -15 l25 0 0 140 c0
160 -9 191 -61 209 -44 15 -107 13 -151 -5z m152 -223 c0 -31 -6 -44 -26 -60
-49 -38 -124 -18 -124 33 0 43 35 65 103 66 l47 0 0 -39z"
                />
                <path
                    d="M2867 833 c-4 -3 -7 -67 -7 -140 0 -149 9 -182 60 -208 42 -22 82
-19 124 10 44 30 46 30 46 5 0 -16 7 -20 30 -20 l30 0 0 174 c0 166 -1 175
-20 181 -11 3 -24 3 -30 0 -6 -4 -10 -56 -10 -123 -1 -124 -3 -130 -54 -170
-37 -28 -61 -28 -91 3 -24 23 -25 29 -25 153 0 109 -3 131 -16 136 -20 8 -29
8 -37 -1z"
                />
                <path
                    d="M3273 833 c-10 -3 -13 -49 -13 -179 l0 -174 30 0 30 0 0 121 0 122
36 33 c43 41 75 43 103 8 19 -24 21 -40 21 -155 l0 -129 30 0 30 0 0 121 0
122 36 33 c43 41 75 43 103 8 19 -24 21 -40 21 -155 l0 -129 31 0 30 0 -3 151
-3 151 -33 29 c-44 39 -95 39 -149 0 l-39 -28 -34 29 c-28 26 -39 30 -76 25
-23 -2 -56 -14 -73 -26 -30 -22 -31 -22 -31 -3 0 15 -23 36 -33 31 -1 0 -8 -3
-14 -6z"
                />
                <path
                    d="M3925 828 c-41 -15 -55 -27 -55 -49 0 -23 7 -23 53 -4 51 21 103 19
127 -5 22 -22 28 -80 8 -80 -48 -1 -140 -21 -162 -35 -60 -40 -49 -142 18
-170 47 -20 74 -19 121 5 47 24 45 24 45 5 0 -9 10 -15 26 -15 l25 0 -3 150
c-3 147 -3 150 -30 177 -22 22 -38 27 -85 30 -32 1 -71 -3 -88 -9z m145 -228
c0 -35 -5 -43 -32 -60 -62 -37 -118 -21 -118 35 0 46 29 65 97 65 l53 0 0 -40z"
                />
                <path
                    d="M4736 830 c-83 -26 -127 -140 -96 -251 21 -75 106 -117 199 -99 67
13 81 20 81 42 0 17 -4 19 -22 13 -13 -4 -50 -9 -84 -12 -53 -4 -64 -2 -88 18
-19 17 -29 37 -33 67 l-6 42 122 0 121 0 0 41 c0 50 -24 103 -56 124 -30 19
-100 27 -138 15z m88 -44 c30 -12 56 -53 56 -87 0 -5 -43 -9 -95 -9 -103 0
-107 3 -83 56 7 15 26 33 43 40 38 17 39 17 79 0z"
                />
                <path
                    d="M5155 827 c-17 -8 -36 -21 -44 -30 -16 -20 -31 -13 -31 14 0 15 -6
20 -20 17 -20 -3 -21 -10 -23 -240 -2 -204 0 -238 13 -241 34 -9 40 3 40 89 0
77 8 101 26 73 3 -6 22 -17 41 -25 44 -19 99 -5 136 33 63 67 58 234 -10 295
-26 24 -91 31 -128 15z m102 -76 c17 -25 23 -48 23 -88 0 -129 -82 -182 -162
-105 -26 23 -28 32 -28 98 0 70 1 73 36 104 49 43 98 40 131 -9z"
                />
                <path
                    d="M5526 830 c-69 -22 -106 -85 -106 -181 0 -69 21 -122 56 -146 57 -37
150 -35 203 5 46 34 63 75 63 152 0 77 -17 118 -62 152 -29 21 -112 31 -154
18z m127 -66 c50 -50 48 -165 -4 -217 -27 -26 -35 -29 -75 -24 -55 6 -81 28
-95 80 -16 59 -3 125 31 159 39 39 105 40 143 2z"
                />
                <path
                    d="M5937 810 c-42 -37 -47 -37 -47 -4 0 21 -4 25 -22 22 -23 -3 -23 -4
-23 -173 l0 -170 28 -3 27 -3 0 120 0 120 35 36 c29 32 38 36 65 30 27 -6 30
-4 30 18 0 24 -16 37 -45 37 -8 0 -30 -13 -48 -30z"
                />
                <path
                    d="M6413 826 c-45 -20 -67 -58 -59 -100 8 -41 20 -52 100 -91 67 -33 82
-61 50 -96 -21 -24 -83 -25 -125 -4 -27 14 -30 14 -36 0 -8 -23 17 -45 66 -56
81 -18 161 25 163 87 2 57 -11 75 -77 105 -75 34 -98 60 -83 94 6 13 22 25 37
28 31 6 79 -2 87 -14 3 -5 9 -9 15 -9 14 0 11 36 -5 49 -22 19 -98 23 -133 7z"
                />
            </g>
        </svg>
    );
}
