/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import arrowCircleLeftFill from '@iconify/icons-eva/arrow-circle-left-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    TextField,
} from '@mui/material';

import saveFill from '@iconify/icons-eva/save-fill';
import editFill from '@iconify/icons-eva/edit-fill';
// components
import CreateFormModal from '../components/CreateFormModal';
import CreateSheetModal from '../components/SheetsQModals';
import { getAllUsers, getForms, deleteForm, getFolders2, addFolder2, updateForm } from '../functions/database';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {
    UserListHead,
    UserListToolbar,
    UserMoreMenu
} from '../components/_dashboard/user';
//
// import USERLIST from '../_mocks_/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'name', label: 'Nombre', alignRight: false }
    // { id: 'company', label: 'Main Store', alignRight: false },
    // { id: 'role', label: 'Role', alignRight: false },
    // { id: 'isVerified', label: 'Verified', alignRight: false },
    // { id: 'status', label: 'Status', alignRight: false },
    // { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(
            array,
            (_user) =>
                _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
    }
    return stabilizedThis.map((el) => el[0]);
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    overflow: 'auto',
    height: '100%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '2rem',
    boxShadow: 24,
    p: 4
};

export default function Form() {
    const [open, setOpen] = useState(false);
    const [openFolder, setOpenFolder] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [hint, setHint] = useState("");
    const [showEdit, setShowEdit] = useState(true);
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [formList, setFormList] = useState([]);
    const [filteredFormList, setfilteredFormList] = useState([]);
    const [showBack, setShowBack] = useState(false);
    const [selectedLV, setSelectedLV] = useState('0');
    const [aFolders, setAfolders] = useState([]);
    const [aFilteredFolders, setAFilteredFolders] = useState([]);
    const [selectedProtocol, setSelectedProtocol] = useState(null);

    useEffect(() => {
        getForms().then((forms) => {
            if (forms) setFormList(forms);
            setfilteredFormList(forms);
        });

        getFolders2().then((folders) => {
            if (folders) setAfolders(folders);
            setAFilteredFolders(folders);
        });
    }, []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = filteredFormList.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const handleUpdateForm = (id, data) => {
        updateForm(id, data);
    };

    const handleDeleteForm = (data) => {
        addFolder2(data);
    }

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredFormList.length) : 0;

    const filteredUsers = applySortFilter(
        filteredFormList,
        getComparator(order, orderBy),
        filterName
    );

    const isUserNotFound = filteredUsers.length === 0;

    return (
        <Page title="TraumaReports: User | Brands">
            <Container>
                <CreateFormModal
                    open={open}
                    handleClose={(refresh) => {
                        setOpen(false);
                        if (refresh) {
                            getForms().then((forms) => {
                                if (forms) setFormList(forms);
                                setfilteredFormList(forms);
                            });
                        }

                    }}
                    style={style}
                    folderId={selectedLV}
                    protocol={selectedProtocol}
                />

                <CreateSheetModal
                    handleAddFolder={(folder) => {
                        const newFolder = {
                            parent: selectedLV,
                            title: "Título de la carpeta",
                            childs: [],
                            id: (aFolders.length + 1).toString(),
                            name: folder
                        };
                        aFolders.push(newFolder);
                        setAfolders(aFolders);
                        addFolder2({ folders: aFolders });
                    }}
                    open={openFolder}
                    handleClose={() => setOpenFolder(false)}
                    style={style}
                />

                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={5}
                >
                    <Typography variant="h4" gutterBottom>
                        Formularios
                    </Typography>
                    <Button
                        variant="contained"
                        component={Button}
                        startIcon={<Icon icon={plusFill} />}
                        onClick={() => {
                            setOpen(true);
                        }}
                    >
                        Crear formulario
                    </Button>
                </Stack>

                <Card
                    style={{
                        padding: '1rem'
                    }}
                >
                    {/* {showBack ?
                        (<Stack>
                            <Button
                                variant="text"
                                component={Button}
                                startIcon={<Icon icon={arrowCircleLeftFill} />}
                                onClick={() => {
                                    const parent = getParent(aFolders, selectedLV)[0];
                                    if (parent) {
                                        if (parent.parent === "0") {
                                            setShowBack(false);
                                        }
                                        setSelectedLV(parent.parent);
                                    } else {
                                        setShowBack(false);
                                    }
                                }}
                            />
                        </Stack>) : null} */}
                    {showBack ?
                        (
                            <Stack display="flex" justifyContent="space-evenly" flexDirection="row" paddingBottom="2rem">
                                <Button
                                    style={{ width: "5%" }}
                                    variant="text"
                                    component={Button}
                                    startIcon={<Icon icon={arrowCircleLeftFill} />}
                                    onClick={() => {
                                        const parent = getParent(aFolders, selectedLV)[0];

                                        if (parent) {
                                            if (parent.parent === "0") {
                                                setShowBack(false);
                                            }
                                            setSelectedLV(parent.parent);
                                        } else {
                                            setShowBack(false);
                                        }
                                    }}
                                />
                                {showEdit ?
                                    <>
                                        <div style={{ width: "80%" }}>
                                            {hint === '' ? 'Nuevo Título' : hint}
                                        </div>

                                        <Button
                                            style={{ width: "5%" }}
                                            variant="contained"
                                            component={Button}
                                            startIcon={<Icon icon={editFill} />}
                                            onClick={() => {
                                                setShowEdit(false);
                                            }}
                                        />
                                    </>
                                    :
                                    <>
                                        <TextField
                                            style={{ width: "85%" }}
                                            id="standard-basic"
                                            label="Título a mostrar"
                                            variant="standard"
                                            value={hint}
                                            onChange={(e) => {
                                                setHint(e.target.value);
                                            }}
                                            InputProps={{
                                                readOnly: showEdit,
                                            }}
                                        />
                                        <Button
                                            style={{ width: "5%" }}
                                            variant="contained"
                                            component={Button}
                                            startIcon={<Icon icon={saveFill} />}
                                            onClick={() => {
                                                const ind = aFolders.findIndex((fold) => fold.id === selectedLV);
                                                if (ind > -1) {
                                                    aFolders[ind].name = hint;
                                                    addFolder2({ folders: aFolders });
                                                }
                                                setShowEdit(true);
                                            }}
                                        />
                                    </>

                                }
                            </Stack>) : null}

                    {filterByLV(aFolders, selectedLV).length > 0 ?
                        <div
                            style={{
                                display: 'grid',
                                alignContent: 'space-around',
                                alignItems: 'stretch',
                                justifyItems: 'stretch',
                                gridTemplateColumns: `repeat(7, 1fr)`,
                                gridAutoColumns: "max-content",
                                gridGap: '15px'
                            }}
                        >
                            {
                                createFolders(filterByLV(aFolders, selectedLV), aFilteredFolders, setSelectedLV, setShowBack, setAfolders, setfilteredFormList, formList, selectedLV, setHint, handleUpdateForm, handleDeleteForm)}
                            <Card
                                key="folder_default"
                                style={{
                                    color: 'rgb(23, 162, 184)',
                                    backgroundColor: '#f5f5f5',
                                    padding: '0.5rem',
                                    width: '100%',
                                    textAlign: 'center',
                                    cursor: 'pointer'
                                }}

                                onClick={() => {
                                    setOpenFolder(true);
                                }}
                            >
                                <div style={{ fontSize: "50px" }}>
                                    <Icon icon={plusFill} />
                                </div>
                                <p>Nueva carpeta</p>
                            </Card>
                        </div>

                        :
                        <Card
                            key="folder_default"
                            style={{
                                color: 'rgb(23, 162, 184)',
                                backgroundColor: '#f5f5f5',
                                padding: '0.5rem',
                                width: '100%',
                                textAlign: 'center',
                                cursor: 'pointer'
                            }}

                            onClick={() => {
                                setOpenFolder(true);
                            }}
                        >
                            <div style={{ fontSize: "50px" }}>
                                <Icon icon={plusFill} />
                            </div>
                            <p>Nueva carpeta</p>
                        </Card>
                    }
                </Card>

                <Card>
                    <UserListToolbar
                        numSelected={selected.length}
                        filterName={filterName}
                        onFilterName={handleFilterByName}
                    />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={filteredFormList.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {filteredUsers.filter(a => a.folder === selectedLV)
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row) => {
                                            const {
                                                id,
                                                name,
                                                profileImg
                                                // role,
                                                // status,
                                                // company,
                                                // avatarUrl,
                                                // isVerified
                                            } = row;
                                            const isItemSelected =
                                                selected.indexOf(name) !== -1;

                                            return (
                                                <TableRow
                                                    hover
                                                    key={id}
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                    selected={isItemSelected}
                                                    aria-checked={
                                                        isItemSelected
                                                    }
                                                    draggable
                                                    onDragStart={(ev) => {
                                                        ev.dataTransfer.setData("id", id);
                                                        ev.target.style.background = 'rgb(189, 245, 227)';
                                                    }}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={
                                                                isItemSelected
                                                            }
                                                            onChange={(event) =>
                                                                handleClick(
                                                                    event,
                                                                    name
                                                                )
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                            spacing={2}
                                                        >
                                                            <Avatar
                                                                alt={name}
                                                                src={profileImg}
                                                            />
                                                            <Typography
                                                                variant="subtitle2"
                                                                noWrap
                                                            >
                                                                {name}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    {/* <TableCell align="left">{company}</TableCell>
                                                    <TableCell align="left">{role}</TableCell>
                                                    <TableCell align="left">
                                                        {isVerified ? 'Yes' : 'No'}
                                                    </TableCell> */}
                                                    {/* <TableCell align="left">
                                                        <Label
                                                            variant="ghost"
                                                            color={
                                                                (status === 'banned' && 'error') ||
                                                                'success'
                                                            }
                                                        >
                                                            {sentenceCase(status)}
                                                        </Label>
                                                    </TableCell> */}
                                                    <TableCell align="right">
                                                        <UserMoreMenu
                                                            onDelete={
                                                                deleteForm
                                                            }
                                                            onRefresh={() => {
                                                                setOpen(true);
                                                                setSelectedProtocol(row);
                                                                setRefresh(true);
                                                            }

                                                            }
                                                            itemId={id}

                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}

                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{ height: 53 * emptyRows }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                align="center"
                                                colSpan={6}
                                                sx={{ py: 3 }}
                                            >
                                                <SearchNotFound
                                                    searchQuery={filterName}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filteredFormList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </Page>
    );
}


const createFolders = (folders, folds, setParent, setShowBack, setAfolders, setfilteredFormList, formList, selectedLV, setHint, handleUpdateForm, handleDeleteForm) => folders.map((folder, ind) => (

    <Card
        key={`folder_${ind}`}
        style={{
            color: 'rgb(23, 162, 184)',
            backgroundColor: '#f5f5f5',
            padding: '0.5rem',
            width: '100%',
            textAlign: 'center'
        }}

        onDrop={(ev) => {
            ev.preventDefault();
            console.log("ewe");
            formList.forEach(form => {
                if (form.id === ev.dataTransfer.getData("id")) {
                    form.folder = folder.id;
                    handleUpdateForm(form.id, form);
                }
            });
            setfilteredFormList(formList.filter((form) => form.folder === selectedLV));
        }}

        onDragOver={(ev) => {
            ev.preventDefault();
            const target = ev.target.classList;
            if (ev.target.tagName === 'IMG') {
                ev.target.parentElement.parentElement.style.background = 'rgb(189, 245, 227)';
            } else if (target.contains("MuiCard-root")) {
                ev.target.style.background = 'rgb(189, 245, 227)';
            }
        }}

        onDragLeave={(ev) => {
            ev.preventDefault();
            const target = ev.target.classList;
            if (ev.target.tagName === 'IMG') {
                ev.target.parentElement.parentElement.style.background = 'rgb(245, 245, 245)';
            } else if (target.contains("MuiCard-root")) {
                ev.target.style.background = 'rgb(245, 245, 245)';
            }
        }}

        onClick={() => {
            setParent(folder.id);
            setHint(folder.name || '');
            setfilteredFormList(formList.filter((form) => form.folder === folder.id));
            setShowBack(true);
        }}
    >
        <div>
            <div
                style={{
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                    background: "red",
                    borderRadius: "50%",
                    height: "25px",
                    width: "25px",
                    color: "white",
                    textAlign: "center",
                    cursor: "pointer"
                }}

                onClick={(e) => {
                    const aFolds = folds.filter((fold) =>
                        fold.id !== folder.id
                    );
                    setAfolders(aFolds);
                    setParent(folder.parent);

                    handleDeleteForm({ folders: aFolds });

                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                x
            </div>
            <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5cvPwmsGl6cCk3QgxZg_m2BC32dJ-Klv4YA&usqp=CAU"
                alt=""
            />
        </div>
        <p>{folder.name}</p>
    </Card>

)
);

const filterByLV = (folders, parent) => folders.filter((folder) => folder.parent === parent);

const getParent = (folders, son) => folders.filter((folder) => folder.id === son);